<template>
    <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small" label-position="top">
        <div class="admin_page_item mt18 pr0 pb0">
            <h1 class="title">店铺经营信息</h1>
            <div class="data_detail mt24">
                <el-form-item label="店铺名称：" prop="storeName">
                    <el-input v-model="form.storeName" placeholder="请输入店铺名称"></el-input>
                </el-form-item>
            </div>
        </div>

        <div class="admin_page_item mt18 pr0 pb0">
            <h1 class="title">店铺经营类目</h1>

            <div class="legend_main pl0">
                <h1 class="title">说明</h1>
                <p class="text">1.请正确选择您要经营的类目，经营类目需选择至商品分类最后一级，确认添加后出现在已选择的经营类目中。</p>
                <p class="text">2.如选择错误可从已选择经营类目列表中删除并重新添加。</p>
                <p class="text">3.经营类目的正确选择将直接影响您店铺的商品发布。</p>
            </div>

            <div class="data_group">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="toAddClass">新增类目</el-button>
            </div>

            <div class="data_detail mt18">
                <el-table :data="skuJsonName" style="width: 100%">
                    <el-table-column prop="level1" label="一级类目" min-width="180px"></el-table-column>
                    <el-table-column prop="level2" label="二级类目" min-width="180px"></el-table-column>
                    <el-table-column prop="level3" label="三级类目" min-width="180px"></el-table-column>
                    <el-table-column prop="address" label="操作" align="center" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" @click="delClass(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div class="page_footer mt24">
            <el-button type="default" size="small" @click="$emit('changeStep', 1)">返 回</el-button>
            <el-button type="primary" size="small" @click="submitForm('form')">确认提交，等待平台审核...</el-button>
        </div>

        <el-dialog class="popup_dialog" title="新增类目" :visible.sync="isPopupAdd" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="1000px">
            <el-form ref="formAdd" :model="formAdd" :inline="true" :rules="formAddRules" size="small" label-position="top">
                <el-form-item label="商品分类：" prop="value">
                    <el-cascader v-model="formAdd.value" :options="classData" :props="classProps" :key="cascaderKey" filterable></el-cascader>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" :loading="isSubmitLoading" @click="submitFormAdd('formAdd')">提  交</el-button>
                    <el-button @click="isPopupAdd = false">关  闭</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-form>
</template>

<script>
  export default {
    name: "adminMerchantEditThird",
    props: {
      classData: {
        type: Array,
        default: () => {return []}
      },
      isSubmitLoading: {
        type: Boolean,
        default: false
      },
      infoData: {
        type: Object,
        default: () => {return {}}
      },
      merchantClassData: {
        type: Array,
        default: () => {return {}}
      }
    },
    data () {
      return {
        form: {
          storeName: '',
          skuJson: [],
        },
        skuJsonName: [],
        rules: {
          storeName: [
            { required: true, message: '请输入店铺名称', trigger: 'blur' }
          ]
        },
        classProps: {
          value: 'id',
          label: 'name',
          children: 'treeList',
          multiple: true
        },
        isPopupAdd: false,
        formAdd: {
          value: []
        },
        formAddRules: {
          value: [
            { required: true, message: '请选中商品分类', trigger: 'change' }
          ]
        },
        cascaderKey: 0
      }
    },
    watch: {
      infoData () {
        this.form.storeName = this.infoData.storeName
      },
      merchantClassData () {
        this.merchantClassData.forEach(item => {
          this.form.skuJson.push({
            level1: item.level1Id,
            level2: item.level2Id,
            level3: item.level3Id,
          })

          this.skuJsonName.push({
            level1: item.level1Name,
            level2: item.level2Name,
            level3: item.level3Name,
          })
        })
      }
    },
    methods: {
      submitFormAdd(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            let skuJson = []
            let skuJsonName = []
            let isError = false
            this.formAdd.value.forEach(item => {
              if (item.length === 3) {
                skuJson.push({
                  level1: item[0],
                  level2: item[1],
                  level3: item[2]
                })
              } else {
                isError = true
              }
            })

            if (isError) {
              this.$message({
                message: '请选择到第三级分类',
                type: 'warning',
                showClose: true,
              });
              return false
            } else {
              this.form.skuJson = skuJson
            }

            this.formAdd.value.forEach(item => {
              let data = {
                level1: '',
                level2: '',
                level3: ''
              }
              for (let i in this.classData) {
                if (this.classData[i].id === item[0]) {
                  data.level1 = this.classData[i].name

                  for (let j in this.classData[i].treeList) {
                    if (this.classData[i].treeList[j].id === item[1]) {
                      data.level2 = this.classData[i].treeList[j].name

                      for (let k in this.classData[i].treeList[j].treeList) {
                        if (this.classData[i].treeList[j].treeList[k].id === item[2]) {
                          data.level3 = this.classData[i].treeList[j].treeList[k].name

                          break;
                        }
                      }

                      break;
                    }
                  }

                  break;
                }
              }

              skuJsonName.push(data)
            })

            this.skuJsonName = skuJsonName

            this.isPopupAdd = false
          }
        })
      },
      submitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.form.skuJson.length > 0) {
              let data = {
                storeName: this.form.storeName,
                skuJson: JSON.stringify(this.form.skuJson)
              }
              this.$emit('submit', data)
            } else {
              this.$message({
                message: '请添加商品分类',
                type: 'warning',
                showClose: true,
              });
            }
          } else {
            return false;
          }
        });
      },
      delClass (index) {
        this.$confirm('是否确认删除该商品分类?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          this.skuJsonName.splice(index, 1)
          this.form.skuJson.splice(index, 1)
          this.formAdd.value.splice(index, 1)
          this.cascaderKey++
        }).catch(() => {});
      },
      toAddClass () {
        let data = [];
        this.form.skuJson.forEach(item => {
          data.push([item.level1, item.level2, item.level3])
        })
        this.formAdd.value = data;
        this.isPopupAdd = true
      }
    }
  }
</script>
